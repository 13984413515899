import router from '@/router'
import Vue from "vue";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import store from './store'
NProgress.configure({ showSpinner: false }) // NProgress Configuration
router.beforeEach((to, from, next) => {
  NProgress.start()

  // set page title
  document.title = to.meta.title || process.env.VUE_APP_TITLE
  // if (to.name != "Home" && to.name != "About"&&to.name != "MeetingHome") {
  //   if (Vue.ls.get("HTTOKEN")) {
  //     if (!store.getters.primaryColor) {
  //       getAction('/adminApi/hotelinfo/infobyhotel', { hotelId: Vue.ls.get("hotelId") }).then((r) => {
  //         if (r.status == 0) {
  //           store.dispatch('editColor', "#" + r.data.color)
  //         }
  //       });
  //     }
  //     if (store.getters.informationRequest.length == 0) {
  //       getAction(`/api/app/current_user/keywordsalldept/nonfdkey`).then((res) => {
  //         if (res.status == 0) {
  //           store.dispatch('editInformationRequest', res.data)

  //         }
  //       });
  //     }
  //     if (store.getters.amcniticsRequest.length == 0) {
  //       getAction(`/api/app/current_user/keywordsalldept/fdkey`).then((res) => {
  //         if (res.status == 0) {
  //           store.dispatch('editAmcniticsRequest', res.data)

  //         }
  //       });
  //     }
  //     if (store.getters.showPhone == '') {
  //       let form = {
  //         hotelId: Vue.ls.get("hotelId"),
  //         size: 10,
  //         current: 1,
  //       };
  //       postAction('/adminApi/textconfig/list', form).then((res) => {
  //         if (res.status == 0) {
  //           res.data.records.forEach((item) => {
  //             if (Vue.ls.get('type') == "guest") {
  //               if (item.requestType == "Guest Room Request") {
  //                 if (item.textResponse == "Enable") {
  //                   store.dispatch('editShowPhone', true)
  //                   return;
  //                 }
  //               }
  //             } else if (Vue.ls.get('type') == "nonguest") {
  //               if (item.requestType == "Meeting Room Request") {
  //                 if (item.textResponse == "Enable") {
  //                   store.dispatch('editShowPhone', true)
  //                   return;
  //                 }
  //               }
  //             }
  //           });
  //         }
  //       });
  //     }
  //     if (store.getters.roomList.length == 0) {
  //       if (Vue.ls.get("type") == "guest") {
  //         getAction(`/api/sys_admin/hotel/room/query2/${"guest"}/${"allmeeting"}`).then(
  //           (res) => {
  //             if (res.status == 0) {
  //               store.dispatch('editRoomList', res.data)
  //             }
  //           }
  //         );
  //       } else if (Vue.ls.get("type") == "nonguest") {
  //         getAction(
  //           `/api/sys_admin/hotel/room/query2/${"guMeeting Roomest"}/${"allmeeting"}`
  //         ).then((res) => {
  //           if (res.status == 0) {
  //             store.dispatch('editRoomList', res.data)
  //           }
  //         });
  //       }
  //     }
  //     next()
  //   }else
  //   next()
  //   //  else {
  //   //   if (to.path == "/about") {
  //   //     next()
  //   //   } else {
  //   //     // getAction(
  //   //     //   `/adminApi/test/qr/about2`
  //   //     // ).then((res) => {
  //   //     // });
  //   //     // next({
  //   //     //   path: '/about'
  //   //     // })
  //   //     // NProgress.done()
  //   //   }
  //   // }
  // } else {
    next()
  // }


})
router.afterEach(() => {
  NProgress.done() // 结束进度条
})
