<template>
  <div style="padding: 8px 16px">
    <a-form>
      <div class="title">
        <span> {{ currentPage }}/{{ fileList.length }} </span>
        <span class="delete" @click="deleteImg">
          <slot name="delete"></slot>
        </span>
      </div>
      <div class="content">
        <a-carousel
          arrows
          class="img-list"
          ref="carousel"
          :after-change="carouselChange"
        >
          <div
            v-if="currentPage > 1"
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 0; z-index: 1"
          >
            <slot name="left"></slot>
          </div>
          <div
            v-if="currentPage < fileList.length"
            slot="nextArrow"
            class="custom-slick-arrow"
            style="right: 0; z-index: 1"
          >
            <slot name="right"></slot>
          </div>
          <div
            style="height: 100%; width: 100%"
            v-for="(item, index) of fileList"
            :key="index"
          >
            <!-- style="height: 420px; width: 238px" -->
            <!-- {{ item.url }} -->
            <div
              class="img"
              :style="'background: url(' + item.url + ')'"
              style=""
              alt=""
            ></div>
          </div>
        </a-carousel>
      </div>
      <van-uploader
        style="display: none"
        :after-read="afterRead"
        multiple
        v-model="fileList"
        ref="uploadImg"
        capture="camera"
        accept="image/*"
      >
      </van-uploader>
      <!-- <a-upload
        style="display: none"
        name="file"
        capture="camera"
        accept="image/*"
        class="upload"
        ref="uploadImg"
        :before-upload="afterRead"
      > 
      </a-upload>-->
      <div class="submit">
        <van-button
          class="button"
          native-type="submit"
          @click.prevent="uploadImg"
        >
          <div style="display: flex; align-items: center">
            <slot name="submit"></slot>
          </div>
        </van-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getAction,
  deleteAction,
  putAction,
  postAction,
  upload,
} from "@/api/manage";
import { thisExpression } from "@babel/types";
export default {
  name: "Photograph",
  data() {
    return {
      url: {
        upload: "/api/common/hotel/data/order/img/upload", // 退出
      },
      currentPage: 1,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      stringList: "stringList",
      languageList: "languageList",
      language: "language",
    }),
    fileList: {
      get() {
        return this.value;
      },
      set() {
        this.$emit("update:value", this.fileList);
      },
    },
  },
  watch: {
    fileList: {
      deep: true,
      handler() {
        if (this.fileList.length <= 0) {
          this.currentPage = 0;
          this.$emit("closed");
        } else if (this.fileList.length == 1) {
          this.currentPage = 1;
        } else if (this.currentPage > this.fileList.length) {
          this.currentPage = this.fileList.length;
        }
      },
    },
  },
  created() {
    // console.log(this.value);
    this.fileList = this.value;
    this.currentPage = this.value.length > 0 ? 1 : 0;
  },
  methods: {
    getFile(base64) {
      let arr = base64.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1]; // 图片后缀
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${111111}.${suffix}`, { type: mime });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    afterRead(e) {
      // console.log(e);
      var formData = new FormData();
      // formData.append("file", e);
      // this.$message.info(e);
      this.getBase64(e.file).then((res) => {
        var maxSize = 100 * 1024; // 最大文件大小为100KB
        var img = new Image();
        img.src = res;
        const that = this;
        that.upload = upload;
        img.onload = function () {
          const _this = this;
          var canvas = document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var width = img.width;
          var height = img.height;
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(_this, 0, 0, width, height);
          var quality = 0.8;
          let newBase64Image, resultBlob;
          do {
            newBase64Image = canvas.toDataURL("image/jpeg", quality);
            resultBlob = that.getFile(newBase64Image);
            quality -= 0.1;
          } while (resultBlob.size > maxSize && quality > 0.1);
          formData.append("file", resultBlob);
          that.$emit("over",true);
          upload(
            `${that.url.upload}?hotelId=${that.$store.state.user.userInfo.hotelId}`,
            formData
          )
            .then(async (res) => {
              if (res.status == 0) {
                // console.log(res);
                // that.$message.info(res);
                // let content = await this.getBase64(file);
                // console.log(content)
                let file = {
                  url: res.data.url,
                  file: e,
                  // content
                };
                that.fileList.push(file);
                // console.log(that.$emit)
                that.$emit("over",false);
                that.$emit("submitV");
              }
            })
            .catch((err) => {});
        };
      });
      this.currentPage = this.fileList.length;
      this.$refs.carousel.goTo(this.currentPage - 1, true);
      return false;
    },
    carouselChange(e) {
      this.currentPage = e + 1;
    },
    deleteImg() {
      // this.currentPage--;
      // console.log(this.fileList.splice(this.currentPage - 1, 1));
      console.log(111111,this.fileList,this.currentPage)
      this.fileList.splice(this.currentPage-1, 1);
      if (this.fileList.length <= 0) this.$emit("closed");
    },
    checkInput($event) {
      if ($event.target.value.length > 200) {
        $event.target.value = $event.target.value.substring(0, 200);
        this.$message.info({
          content: this.stringList["200code"],
          key: "200code",
          icon: () => {
            return "";
          },
          // duration: 500,
        });
      }
      this.$emit("update:value", $event.target.value);
    },
    // 上传回调
    uploadImg() {
      this.$refs.uploadImg.chooseFile();
      // this.$nextTick(() => {
      //   document.querySelector(".upload input").click();
      // }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  height: 26px;
  opacity: 1;
  font-family: Arial;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;
  color: #5a578c;
  position: relative;
  .delete {
    position: absolute;
    left: -2px;
  }
}
.content {
  margin-top: 1.5rem;
  /deep/.img-list {
    height: 422px;
    opacity: 1;
    border: 1px solid #afafaf;
    .slick-slider,
    .slick-list {
      height: 100%;
      width: 100%;
      .slick-track {
        height: 100%;
        .slick-current {
          height: 100%;
          div {
            height: 100%;
          }
        }
      }
    }
    .slick-slide {
      div {
        height: 100%;
        width: 100%;
      }
    }
    .custom-slick-arrow {
      width: 24px;
      height: 24px;
      text-align: center;
    }
    .slick-dots-bottom {
      display: none !important;
    }
    .img {
      height: 100%;
      width: 100%;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
    }
  }
}
.submit {
  margin: 32px 0 16px;
  text-align: center;
  .button {
    width: 160px;
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
    /deep/.van-uploader__preview {
      display: none;
    }
  }
}
// 未生效
/deep/.ant-message-notice-content {
  width: 320px;
  height: 108px;
  padding: 32px;
  border-radius: 32px;
  background: #f8f7f8;
  .ant-message-custom-content.ant-message-info {
    height: 44px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    color: #101010;
    .anticon.anticon-info-circle {
      display: none;
    }
  }
}
</style>
