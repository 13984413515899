const config = {
  state: {
    // stringList: {},
    showPopup: false,
    // languageList: []
  },
  mutations: {
    EDIT_SHOWPOPUP: (state, data) => {
      state.showPopup = data;
    },
  },
  actions: {
    editShowPopup: ({ commit, dispatch }, data) => {
      commit("EDIT_SHOWPOPUP", true);

      // dispatch("editLanguage", localStorage.getItem("language") || "en")
    },
    closeShowPopup: ({ commit, dispatch }, data) => {
      commit("EDIT_SHOWPOPUP", false);

      // dispatch("editLanguage", localStorage.getItem("language") || "en")
    },
    // editLanguage: async ({ commit }, data) => {
    //   localStorage.setItem("language", data)
    //   commit("EDIT_LANGUAGE", data);
    //   let stringArray = await import(`@/data/String_${data}.json`);
    //   let stringList = {...stringArray}
    //   commit("EDIT_STRING_LIST", stringList);
    // }
  },
};

export default config;
