const config = {
  state: {
    stringList: {},
    language: "",
    languageList: []
  },
  mutations: {
    EDIT_STRING_LIST: (state, data) => {
      state.stringList = data;
    },
    EDIT_LANGUAGE: (state, data) => {
      state.language = data;
    },
    EDIT_LANGUAGE_LIST: (state, data) => {
      state.languageList = data;
    },
  },
  actions: {
    editLanguageList: ({ commit, dispatch }, data) => {
      commit("EDIT_LANGUAGE_LIST", data);

      dispatch("editLanguage", localStorage.getItem("language") || "en")
    },
    editLanguage: async ({ commit }, data) => {
      localStorage.setItem("language", data)
      commit("EDIT_LANGUAGE", data);
      let stringArray = await import(`@/data/String_${data}.json`);
      let stringList = {...stringArray}
      commit("EDIT_STRING_LIST", stringList);
    }
  },
};

export default config;
