<template>
  <div style="padding: 8px 16px">
    <a-form :form="form" @submit="handleSubmit">
      <div class="title">{{ stringList.RoomCondition }}</div>
      <div class="content">
        <div class="item">
          <div class="label">{{ stringList.room }}</div>

          <a-form-item
            style="flex: 1"
            :validate-status="validateStatus || 'success'"
            :help="errorMsg || ''"
          >
            <a-select
              ref="room"
              show-search
              :placeholder="stringList.room"
              :filter-option="false"
              option-filter-prop="children"
              v-model="room"
              @search="roomSearch"
              @focus="clearRoomNumber"
              dropdownClassName="room-select"
              notFoundContent="Loading"
            >
              <a-select-option
                v-for="item in roomList"
                :key="item.id"
                :value="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>

        <div class="room-type">
          <div class="label">{{ stringList.this_room }}</div>
          <a-radio-group v-model="type">
            <a-radio style="display: block" value="1">
              {{ stringList.vacant_checked_out }}
            </a-radio>
            <a-radio style="display: block" value="2">
              {{ stringList.occupied_stay_over }}
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="submit">
        <van-button class="button" html-type="submit">{{
          stringList.start
        }}</van-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Room",
  data() {
    return {
      notFoundContent: "",
      validateStatus: "success",
      errorMsg: "",
      roomList: [],
      lastNumber: "",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  props: {
    roomNumber: {
      required: true,
      default: undefined,
    },
    roomArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    roomType: {
      type: String,
      default: "1",
    },
  },
  computed: {
    ...mapGetters({
      stringList: "stringList",
    }),
    room: {
      get() {
        return this.roomNumber;
      },
      set(value) {
        this.$emit("update:roomNumber", value);
      },
    },
    type: {
      get() {
        return this.roomType;
      },
      set(value) {
        this.$emit("update:roomType", value);
      },
    },
  },
  watch: {
    room: {
      handler(value) {
        if (value)
          if (
            this.roomArray.length>0&&this.roomArray.findIndex((item) => item.name == this.roomNumber) ==
            -1
          ) {
            // console.log("room error")
            this.validateStatus = "error";
            this.errorMsg = this.stringList.invalid_room;
          } else {
            this.validateStatus = "success";
            this.errorMsg = "";
          }
      },
      immediate: true,
    },
    roomArray() {
      // console.log("roomArray");
      if (this.roomNumber)
        if (
          this.roomArray.length>0&&this.roomArray.findIndex((item) => item.name == this.roomNumber) == -1
        ) {
          this.validateStatus = "error";
          this.errorMsg = this.stringList.invalid_room;
        } else {
          this.validateStatus = "success";
          this.errorMsg = "";
        }
    },
  },
  created() {
    this.roomList = [...this.roomArray];
    setTimeout(() => {
      this.room = this.roomNumber;
      // console.log(this.roomList);
    }, 100);
  },
  mounted() {},
  methods: {
    roomSearch(value) {
      if (value) {
        this.roomList = this.roomArray.filter((item) => {
          return item.name.indexOf(value) != -1;
        });
        if(this.roomList.length==1){
          // console.log(11111,this.$refs.room.$el)
          this.room = this.roomList[0].name
          this.$refs.room.$el.click()
        }
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomList = [];
        this.notFoundContent = "";
      }
    },

    clearRoomNumber() {
      if (this.roomNumber == undefined || this.roomNumber == "") {
        this.roomList = [...this.roomArray];
      } else {
        this.roomList = [];
        this.notFoundContent = "";
        this.roomList = [...this.roomArray];
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log(!this.roomNumber);
          if (!this.roomNumber) {
            this.validateStatus = "error";
            this.errorMsg = this.stringList["select_enter_room#"];
          } else if (this.roomNumber) {
            this.validateStatus = "success";
            this.errorMsg = "";
            if (
              this.roomArray.findIndex(
                (item) => item.name == this.roomNumber
              ) == -1
            ) {
              this.validateStatus = "error";
              this.errorMsg = this.stringList.invalid_room;
            } else {
              this.validateStatus = "success";
              this.errorMsg = "";
              this.$emit("submit", {
                roomNumber: this.roomNumber,
                roomType: this.roomType,
              });
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  height: 3.25rem;
  opacity: 1;
  font-family: Arial;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;
  width: 90%;
  margin: auto;
  color: #5a578c;
}
.content {
  margin-top: 2rem;
  .item {
    display: flex;
    align-items: center;
    .label {
      min-width: 4.625rem;
      opacity: 1;
      margin-bottom: 24px;
      font-family: Arial;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      word-break: break-all;
      color: #050101;
    }
    .room-num /deep/.ant-select-selection {
      border-radius: 12px;
    }
    /deep/.ant-select-selection {
      height: 48px;
      border-radius: 12px;
      opacity: 1;
      background: #ffffff;
      border: 1px solid #afafaf;
      .ant-select-selection__placeholder {
        text-align: center;
      }
      .ant-select-selection__rendered {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
      }
    }

    .show-placeholder {
      /deep/.ant-select-selection__placeholder {
        display: block !important;
      }
    }
    // /deep/.ant-select-search__field__wrap{
    //   min-width: 80px;
    // }
    /deep/.has-error {
      .ant-select-selection {
        background: #fee0d6;
        border-color: #df714f;

        svg {
          fill: #df714f;
        }
      }
      .ant-select-selection-selected-value {
        color: #df714f !important;
      }
      .ant-select-selection__placeholder {
        color: #df714f !important;
      }
    }
    /deep/.ant-form-explain {
      margin-top: 2px;
      line-height: 16px;
      color: #df714f;
      font-size: 14px;
      font-family: Arial;
    }
  }
  .room-type {
    // margin-top: 24px;
    .label {
      opacity: 1;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      color: #3d3d3d;
    }
    .ant-radio-group {
      margin-left: 23px;
      /deep/span:not(ant-radio) {
        opacity: 1;
        font-family: Arial;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        color: #3d3d3d;
        z-index: 1;
      }
      /deep/.ant-radio-wrapper-checked {
        .ant-radio-inner::after {
          background: #afafaf;
        }
        .ant-radio-inner {
          border-color: #afafaf;
        }
      }
    }
  }
}
.submit {
  margin: 24px 0 16px;
  text-align: center;
  .button {
    width: 160px;
    height: 48px;
    border-radius: 12px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    letter-spacing: 0em;
    color: #ffffff;
  }
} 

// /deep/.room-select {
//   .ant-select-dropdown-menu {
//     max-height: 150px !important;
//   }
// }
</style>
