import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/broker/api/v1/user/login',
    method: 'post',
    data
  })
}
export function getUserInfo(data) {
  return request({
    url: '/common/user/current_user',
    method: 'post',
    data
  })
}
