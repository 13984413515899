const qrcode = {
    state: {
        amcniticsRequest: [],
        informationRequest:[],
        showPhone: '',
        roomList: []
    },
    mutations: {
        EDIT_AMCNITICS_REQUEST: (state, data) => {
            state.amcniticsRequest = data;
        },
        EDIT_INFORMATION_REQUEST: (state, data) => {
            state.informationRequest = data;
        },
        EDIT_SHOW_PHONE: (state, data) => {
            state.showPhone = data;
        },
        EDIT_ROOMLIST: (state, data) => {
            state.roomList = data;
        },
    },
    actions: {
        editAmcniticsRequest: ({ commit }, data) => {
            commit("EDIT_AMCNITICS_REQUEST", data);
        },
        editInformationRequest: ({ commit }, data) => {
            commit("EDIT_INFORMATION_REQUEST", data);
        },
        editShowPhone: ({ commit }, data) => {
            commit("EDIT_SHOW_PHONE", data);
        },
        editRoomList: ({ commit }, data) => {
            commit("EDIT_ROOMLIST", data);
        },
    },
};

export default qrcode;