<template>
  <div style="padding: 8px 16px">
    <a-form>
      <div class="title" v-if="!isRS">{{ stringList.room }}:{{ roomNumber }}</div>
      <div class="circle"><slot></slot></div>

      <div class="content">
        <!-- 下面内容不要换行,否则会出现半脚空格, -->
        <div class="describe">{{ stringList.successfully }}</div>
        <!-- {{!!label1.find(item=>!!formData[item.id])}}111
        {{ label1 }} -->
         <!-- {{ formData.water.num||formData.pet.type||formData.smoking.type||formData.damages[0].description||label1.find(item=>!!formData[item.id]) }}222 -->
        <div class="box" v-if="!!formData.water.num||!!formData.pet.type||!!formData.smoking.type||!!formData.damages[0].description||!!label1.find(item=>!!formData[item.id])">
          <div class="title1">{{ stringList.room_charges }}</div>
          <li v-if="formData.water.num">({{ formData.water.num }}){{ " " + stringList.bottle_water }}</li>
          <li v-if="formData.pet.type">{{ label1.find(item=>item.keyword=='Pet in Room')?label1.find(item=>item.keyword=='Pet in Room')[language]||label1.find(item=>item.keyword=='Pet in Room').keyword:stringList.two_pet_in_room }}</li>
          <li v-if="formData.smoking.type">{{ stringList.smoking }}</li>
          <!-- {{ formData }} -->
          <template v-for="(item, index) in formData">
            <!-- {{ index }} -->
            <li v-if="label1.find(item=>item.id==index)" :key="index">{{language=="en"?label1.find(item=>item.id==index).keyword:label1.find(item=>item.id==index)[language]}}</li>
          </template>
          <template v-for="(item, index) of formData.damages">
            <li v-if="item.description" :key="index">{{ getLabel("guestroom_damage", 0) }} - {{ item.description }}</li>
          </template>
        </div>
        <div class="box" v-if="formData.toilet.type||formData.sink.type||formData.items[0].type||isItems2||formData.otherItems[0].type">
          <div class="title1">{{ stringList.maintenance_checklist }}</div>
          <li v-if="formData.toilet.type">{{ stringList.toilet }} {{ getTypeName(formData.toilet.type)?.action[language] }}</li>
          <li v-if="formData.sink.type">{{ stringList.sink }} {{ getTypeName(formData.sink.type)?.action[language] }}</li>
          <template v-for="(item, index) of formData.items2">
            <li v-if="item.type||(item.photo.length>0&&item.noSelectType)" :key="index">{{ (language=="en"?getTypeName(item.type||item.noSelectType).keyword:getTypeName(item.type||item.noSelectType)[language])||(item.type||item.noSelectType) }}</li>
            <!-- {{ getLabel("other_items", 0) }} -  -->
          </template>
          <template v-for="(item, index) of formData.items">
            <li v-if="item.type" :key="index">{{ (language=="en"?getTypeName(item.type).keyword:getTypeName(item.type)[language])||item.type }}</li>
            <!-- {{ getLabel("other_items", 0) }} -  -->
          </template>
          <template v-for="(item, index) of formData.otherItems">
            <li v-if="item.type" :key="index">{{ item.type }}</li>
            <!-- {{ getLabel("other_items", 0) }} -  -->
          </template>
        </div>
        <div class="box" v-if="isRS">
          <template v-for="(item, index) in formData.RS">
            <div :key="index">
              <!-- {{ item }}{{ index }} -->
              <div class="title1">{{ index }}</div>
              <template v-for="(it, idx) of item">
                <li :key="idx">{{ it.keyword.slice(5) }}</li>
                <!-- {{ getLabel("other_items", 0) }} -  -->
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="footer">
        <van-button class="button" @click="submit" native-type="submit">{{
          stringList.OK
        }}</van-button>
      </div>
      <div class="submit" v-if="!isRS">
        <span @click="cancel">
          {{ stringList.cancel }}
        </span>
      </div>
    </a-form>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Detail",
  data() {
    return {
      labelList: Vue.ls.get("labelList"),
    };
  },
  props: {
    roomNumber: {
      type: String,
      required: true,
      default: undefined,
    },
    formData: {
      required: true,
    },
    label1:{
      type: Array,
    },
    isRS:{
      type: Boolean,
      default: ()=>{
        return false
      },
    }
  },
  computed: {
    ...mapGetters({
      stringList: "stringList",
      languageList: "languageList",
      language: "language",
    }),
    roomList() {
      return this.roomArray;
    },
    isItems2(){
      return this.formData.items2.findIndex(item=> item.type||(item.photo.length>0&&item.noSelectType))!=-1
    }
  },
  methods: {
    getTypeName(type) {
      let label = this.labelList.find((item) => (item.id == type)||(item.keyword == type));
      // console.log(label,type,this.labelList,this.labelList.find((item) => item.id == type))
      return label||{};
    },
    getLabel(label, index) {
      if (index == 0) {
        return this.stringList[label];
      } else {
        return this.stringList[label + 1].replace("1", index);
      }
    },
    languageChange(value) {
      this.$store.dispatch("editLanguage", value);
    },
    logOut() {
      console.log("log out!");
    },
    submit() {
      this.$emit("complete");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  height: 1.625rem;
  opacity: 1;
  justify-content: center;
  font-family: Arial;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #5a578c;
}
.circle {
  margin: 16px auto;
  width: 56px;
  height: 56px;
  opacity: 1;
  background: #ffffff;
  box-sizing: border-box;
  // border: 2px solid #cbcfe0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.content {
  .describe {
    height: 40px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #101010;
    margin-bottom: 20px;
  }
  .box {
    
    .title1 {
      margin-top: 16px;
      opacity: 1;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      color: #101010;
      margin-bottom: 8px;
      text-align: center;
    }
    li::before {
      content: "·";
      position: relative;
      // left: -2px;
      font-weight: 700;
      color: #101010;
      font-size: 20px;
      margin: 0 6px;
    }
    li {
      // word-break: break-all !important;
      display: flex;
      //   margin-left: 32px;
      opacity: 1;
      font-family: Arial;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;

      color: #101010;
    }
  }
}
.footer {
  margin: 32px auto 24px;
  text-align: center;
  .button {
    width: 200px;
    height: 48px;
    border-radius: 6px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
  }
}
.submit {
  opacity: 1;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;
  text-decoration: underline;
  color: #101010;
  margin-bottom: 16px;
}
</style>
