<template>
  <div style="padding: 45px 16px 12px">
    <a-form>
      <div class="title">{{ stringList.settings }}</div>
      <div class="content">
        <div class="user-item">
          <div style="margin-right: 16px" class="label-icon">
            <slot name="user"></slot>
          </div>
          <div>

            <div class="user-name">
              {{ $store.state.user.userInfo.firstName }}
              {{ $store.state.user.userInfo.lastName }}
            </div>
            <span class="depart">
              {{ $store.state.user.userInfo.hotelDeptName }}
            </span>
          </div>
        </div>
        <a-divider />
        <div class="select-label">{{ stringList.select_language }}</div>
        <div class="language-item">
          <div style="margin-right: 16px" class="label-icon">
            <slot></slot>
          </div>
          <a-select
            size="large"
            style="flex: 1"
            class="language"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @change="languageChange"
            :value="language"
            dropdownClassName="language-drop"
          >
            <a-select-option
              v-for="item in languageList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.type }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="submit">
        <div class="button" @click="logOut">
          {{ stringList.log_out }}
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import Vue from "vue";
export default {
  name: "Setting",
  data() {
    return {
      url: {
        logOut: "/api/common/user/current_user/logout", // 退出
      },
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      stringList: "stringList",
      languageList: "languageList",
      language: "language",
    }),
    roomList() {
      return this.roomArray;
    },
  },
  methods: {
    languageChange(value) {
      this.$store.dispatch("editLanguage", value);
    },
    logOut() {
      getAction(`${this.url.logOut}`)
        .then((res) => {
          if (res.status == 0) {
            // console.log(res);
            Vue.ls.remove("HTTOKEN");
            let inno = Vue.ls.get("user").hotelName
            // console.log(inno)
            this.$router.push({ name: "Login", query:{inno} });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  // height: 3.25rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #EDEDED;
  padding: 16px 16px 12px 40px;
  border-radius: 20px 20px 0px 0px;
  height: 70px;
  opacity: 1;
  font-family: Arial;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  justify-content: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;

  color: #5a578c;
}
.content {
  margin-top: 20px;
  .user-item {
    height: 44px;
    display: flex;
    align-items: center;
    position: relative;
    // justify-content: center;
    .label-icon {
      // position: absolute;
      left: 0;
      display: flex;
    align-items: center;
    }
  }
  .user-name {
    flex: 1;
    height: 32px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: left;
    letter-spacing: 0px;
    color: #3d3d3d;
  }
  /deep/.ant-divider {
    background: #8e8f90;
    margin: 1rem 0;
  }
  .select-label {
    height: 26px;
    opacity: 1;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    letter-spacing: 0px;

    color: #333333;
  }
  .language-item {
    height: 3rem;
    display: flex;
    align-items: center;
    margin: 8px 0 32px;

    /deep/.ant-select-selection {
      height: 48px;
      display: flex;
      align-items: center;
      border-radius: 12px;
      opacity: 1;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #afafaf;
    }
  }
}
.submit {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    display: flex;
  align-items: center;
  justify-content: center;
    width: 200px;
    height: 48px;
    border-radius: 8px;
    opacity: 1;
    background: #ff6600;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.72px;
    text-align: center;
    // display: flex;
    // align-items: center;
    letter-spacing: 0em;
    color: #ffffff;
    border: 0;
  }
}
.depart{
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  
  font-variation-settings: "opsz" auto;
  color: #5B5B5B;
}
</style>
