const config = {
  state: {
    primaryColor: "",
  },
  mutations: {
    EDIT_COLOR: (state, data) => {
      state.primaryColor = data;
      //修改全局变量
      document
        .getElementsByTagName("body")[0]
        .style.setProperty("--primaryColor", `${state.primaryColor}`);
    },
  },
  actions: {
    editColor: ({ commit }, data) => {
      commit("EDIT_COLOR", data);
    },
  },
};

export default config;
